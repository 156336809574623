import React, { useEffect, useRef, useState } from "react";

import {
  SCProjectLink,
  Circle,
  Container,
} from "src/components/allWorksButton/styled";

export const AllWorksButton = () => {
  const ref = useRef();
  const refLink = useRef();

  const [isIntersecting, setIntersecting] = useState(false);
  const [isHover, setHover] = useState(true);

  const onScroll = () => {
    if (isIntersecting) return;
    if (!ref.current) {
      setIntersecting(false);
      return;
    }
    const top = ref.current?.getBoundingClientRect().top;
    const innerHeight = window.innerHeight / 4;

    setIntersecting(
      top - innerHeight >= 0 && top + innerHeight <= window.innerHeight
    );
  };

  useEffect(() => {
    const element =
      typeof window !== "undefined" && window.innerWidth <= 640
        ? document.querySelector(".smooth-wrapper")
        : document;

    onScroll();
    element.addEventListener("scroll", onScroll);
    return () => element.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    if (isIntersecting) {
      const id = setTimeout(() => {
        setHover(false);
      }, 400);

      return () => clearTimeout(id);
    }
  }, [isIntersecting]);

  return (
    <Container
      ref={ref}
      className={`${isIntersecting ? "fadeIn" : "hide"} ${
        isHover ? "hover" : ""
      }`}
      onClick={() => refLink.current?.click()}
    >
      <Circle opacity={0.05} scale={2.2} time={1.5}>
        <Circle opacity={0.3} scale={1.8} time={1}>
          <Circle opacity={0.5} scale={1.4} time={0.5}>
            <SCProjectLink as="a" ref={refLink} href="/projects/">
              All works
            </SCProjectLink>
          </Circle>
        </Circle>
      </Circle>
    </Container>
  );
};
