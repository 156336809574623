import React, { useRef, useEffect, useState } from "react";
import { Image, ImageSmall, ImageMessage, BombWrapper } from "./styled";
import BombImg from "assets/images/bomb.png";
import BombSmallImg from "assets/images/bomb-small.png";
import BombMessageImg from "assets/images/bomb-message.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const Bomb = () => {
  const ref = useRef();

  const refBomb = useRef();
  const refSmallBomb = useRef();
  const refMessage = useRef();

  const [mount, setMount] = useState(false);

  useEffect(() => {
    let isNotActive = true;
    if (mount) {
      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: refBomb.current,
        start: "top center",
        scrub: true,
        onUpdate: ({ progress }) => {
          if (
            isNotActive &&
            refBomb.current &&
            (window.innerWidth <= 768 || progress >= 0.5)
          ) {
            refBomb.current.classList.add("move");
            refSmallBomb.current.classList.add("move");
            refMessage.current.classList.add("move");
            isNotActive = false;
          }
        },
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  return (
    <BombWrapper ref={ref}>
      <Image src={BombImg} alt="bomb" ref={refBomb} />
      <ImageSmall src={BombSmallImg} alt="bomb" ref={refSmallBomb} />
      <ImageMessage src={BombMessageImg} alt="bomb" ref={refMessage} />
    </BombWrapper>
  );
};
