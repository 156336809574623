import styled from "styled-components";
import { theme, globalMargin } from "src/styles/global";
import { Link } from "gatsby";
import ArrowDown from "src/assets/icons/arrow-down.svg";
import { H2 as _H2, Text as _Text } from "src/styles/components";

export const Text = styled.a`
  color: ${({ color = theme.colors.graySuit }) => color};
  font-family: ${({ family = "Montserrat" }) => `${family}, sans-serif`};
  font-weight: ${({ weight = 400 }) => weight};
  font-size: ${({ size = 14 }) => size + "px"};
  line-height: ${({ lineHeight = 1.5 }) => lineHeight};
  text-align: ${({ align }) => align};
  margin: 0;
  ${globalMargin};

  @media (${theme.device.mobile}) {
    max-width: 90%;
  }

  &:hover {
    color: ${theme.colors.white};
  }
`;

export const CustomLink = styled(Link)`
  position: ${({ position = "static" }) => position};
  right: 0;
  bottom: 0;
  min-width: 80px;
  color: ${theme.colors.scarpaFlow};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: block;
  &:hover {
    color: ${theme.colors.white};
  }

  @media (${theme.device.tablet}) {
    transform: ${({ position = "static" }) =>
      position === "static"
        ? "transform: translateY(0px)"
        : "transform: translateY(40px)"};
  }
`;

export const CustomHref = styled.a`
  position: ${({ position = "static" }) => position};
  right: 0;
  bottom: 0;
  min-width: 80px;
  color: ${theme.colors.scarpaFlow};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: block;

  @media (${theme.device.tablet}) {
    transform: ${({ position = "static" }) =>
      position === "static"
        ? "transform: translateY(0px)"
        : "transform: translateY(40px)"};
    
    > svg {
      width: 60px;
      height: 60px;
    }
  }
`;

export const H2 = styled(_H2)`
  font-size: 3vw;

  @media (${theme.device.desktop}) {
    font-size: 4vw;
  }

  @media (${theme.device.tablet}) {
    font-size: 7.5vw;
  }

  @media (${theme.device.mobile}) {
    font-size: 10vw;
  }

  margin-bottom: 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: ${({ direction = "column" }) => direction};
  align-items: flex-end;
  gap: 50px;

  max-width: 650px;

  &[data-last="true"] {
    transform: translateX(-5vw);
  }

  &:hover svg {
    fill: white;
  }

  &:hover img {
    transform: scale(1.05);
  }

  &:hover ${CustomHref}, &:hover ${CustomLink}, &:hover ${Text} {
    color: ${theme.colors.white};
  }

  @media (${theme.device.tablet}) {
    gap: 18px;

    &[data-card] {
      margin-bottom: 40px;
    }
  }

  @media (${theme.device.mobile}) {
    flex-wrap: wrap;
  }
`;

export const CardFull = styled(Card)`
  position: relative;
  ${({ position }) => {
    if (position === "left") {
      return "align-items: end;";
    }

    if (position === "right") {
      return "align-items: start;";
    }

    return "";
  }}

  max-height: 700px;
  margin-bottom: 100px;
  height: 40vw;

  ${({ isFull }) => {
    if (isFull) {
      return `
        min-height: 100vh; 
        max-height: 100vh;
        padding: 2vw;
        box-sizing: border-box;
      `;
    }
  }}
  @media (${theme.device.desktop}) {
    height: 60vw;
  }

  @media (${theme.device.laptop}) {
    height: 90vw;
  }

  @media (${theme.device.tablet}) {
    height: 90vw;
  }

  @media (${theme.device.mobile}) {
    height: 180vw;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;

  @media (${theme.device.tablet}) {    
    align-items: flex-start;
  }
`;

export const LinkWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 80px;
  height: 80px;
  display: block;

  @media (${theme.device.tablet}) {
    width: 60px;
    height: 60px;
  }
`;

export const CardContent = styled(CardFooter)`
  justify-content: space-between;
  z-index: 1;

  max-width: 40vw;
  height: 100%;

  ${({ position }) => {
    if (position === "left") {
      return "margin-right: 50vw;";
    }

    if (position === "right") {
      return "margin-left: 50vw;";
    }

    return "";
  }};

  @media (${theme.device.tablet}) {
    max-width: 70vw;

    ${({ position }) => {
      if (position === "left") {
        return "margin-right: 20vw;";
      }

      if (position === "right") {
        return "margin-left: 20vw;";
      }

      return "";
    }};
  }

  @media (${theme.device.mobile}) {
    max-width: 90vw;

    ${({ position }) => {
      if (position === "left") {
        return "margin-right: 5vw;";
      }

      if (position === "right") {
        return "margin-left: 5vw;";
      }

      return "";
    }};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    transition: transform 0.3s cubic-bezier(0.14, 0.48, 0, 0.96);
    transform: scale(1);
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ArrowIcon = styled(ArrowDown)`
  color: ${theme.colors.scarpaFlow};
  transform: rotate(-30deg);
  margin-right: 10px;
`;

export const Separator = styled.div`
  height: ${({ size }) => size}px;
`;

export const MediaBLock = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
`;
