import styled from "styled-components";
import { theme } from "src/styles/global";
import { layoutSize } from "styles/mixin";

export const ProjectsGridWrapper = styled.section`
  column-gap: 10vw;
  display: flex;
  ${layoutSize};
  margin: 0 auto;
  padding: 100px 0;

  @media (${theme.screenSize.tablet}) {
    padding-top: 0;
  }

  @media (${theme.screenSize.mobile}) {
    padding-bottom: ${({ isProjectPage }) => isProjectPage && 0};
  }
`;

export const ProjectsGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10vw;
  max-width: 40vw;
  width: 40vw;
  align-items: center;
  position: relative;

  @media (${theme.device.tablet}) {
    row-gap: unset;
    max-width: 100vw;
    width: 100%;
  }
`;

export const BombWrapper = styled.div`
  position: relative;
  min-height: 50px;
  z-index: 2;

  @media (${theme.screenSize.mobile}) {
    top: 100px;
  }
`;

export const Image = styled.img`
  z-index: 1;
  position: absolute;
  max-width: 450px;
  height: auto;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -120%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.14, 0.48, 0, 0.96);

  &.move {
    opacity: 1;
    transform: translate(-50%, -20%);

    @media (${theme.screenSize.tablet}) {
      transform: translate(0, 25%);
    }

    @media (${theme.screenSize.mobile}) {
      transform: translate(-20%, -20%);
    }
  }

  @media (${theme.screenSize.desktop}) {
    width: 22vw;
  }

  @media (${theme.screenSize.laptop}) {
    width: 28vw;
  }

  @media (${theme.screenSize.tablet}) {
    width: 50vw;
    position: relative;
    left: 0;
    transform: translate(0, 0);
  }

  @media (${theme.screenSize.mobile}) {
    width: 60vw;
  }
`;

export const ImageSmall = styled.img`
  z-index: 1;
  position: absolute;
  max-width: 100px;
  height: auto;
  left: 50%;
  top: 0px;

  opacity: 0;
  transform: translate(-250%, -600%);
  transition: all 1s cubic-bezier(0.14, 0.48, 0, 0.96);
  transition-delay: 0.3s;

  &.move {
    opacity: 1;
    transform: translate(-250%, -120%);

    @media (${theme.screenSize.tablet}) {
      transform: translate(-250%, 220%);
    }

    @media (${theme.screenSize.mobile}) {
      transform: translate(-390%, -120%);
    }
  }

  @media (${theme.screenSize.desktop}) {
    width: 5vw;
  }

  @media (${theme.screenSize.laptop}) {
    width: 7vw;
  }

  @media (${theme.screenSize.tablet}) {
    width: 10vw;
    position: relative;
    left: -25vw;
    top: -50vw;
  }

  @media (${theme.screenSize.mobile}) {
  }
`;

export const ImageMessage = styled.img`
  z-index: 0;
  position: absolute;
  max-width: 450px;
  height: auto;
  left: 0;
  top: 0;

  opacity: 0;
  transform: translate(0, 50%) rotate(35deg);
  transform-origin: left top;
  transition: all 1s cubic-bezier(0.14, 0.48, 0, 0.96);
  transition-delay: 0.6s;
  &.move {
    opacity: 1;
    transform: translate(0, 50%) rotate(0deg);

    @media (${theme.screenSize.tablet}) {
      transform: translate(10%, 100%);
    }

    @media (${theme.screenSize.mobile}) {
      width: 115%;
      transform: translate(-7%, 5%) rotate(0deg);
    }
  }

  @media (${theme.screenSize.desktop}) {
    width: 22vw;
  }

  @media (${theme.screenSize.laptop}) {
    width: 28vw;
  }

  @media (${theme.screenSize.tablet}) {
    width: 50vw;
    left: 20vw;
  }

  @media (${theme.screenSize.mobile}) {
    max-width: 70vw;
  }
`;
