import React, { useEffect, useState } from "react";
import { ProjectCard } from "src/components/cards";
import {
  ProjectsGridWrapper,
  ProjectsGridColumn,
} from "src/components/projectsGrid/styled";

import { AllWorksButton } from "src/components/allWorksButton/allWorksButton";
import { Bomb } from "./bomb";
import { InViewport } from "src/components/inViewport";

export const ProjectsGrid = ({
  isSecondBegin = false,
  isProjectsLink = false,
  isBomb = false,
  isProjectPage = false,
  data,
  id,
}) => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleDevice = () => setIsTablet(window.innerWidth <= 768);
    handleDevice();

    window.addEventListener("resize", handleDevice);
    return () => window.removeEventListener("resize", handleDevice);
  },[]);

  const renderOneColumn = (data) => {
    return (
      <ProjectsGridColumn>
        {isBomb && <Bomb />}
        {data.map(
          ({ title, subTitle, imageName, slug, externalLink }, index) => (
            <InViewport key={`${index}-${imageName}-single`} animationDelay="0.3s">
              <ProjectCard
                key={`${index}-${imageName}-single`}
                title={title}
                description={subTitle}
                image={`/projects/${imageName}`}
                slug={slug}
                externalLink={externalLink}
              />
            </InViewport>
          )
        )}
        {isProjectsLink && <AllWorksButton />}
      </ProjectsGridColumn>
    );
  };

  const renderTwoColumn = (data) => {
    const { even, odd } = data.reduce(
      (res, { title, subTitle, imageName, slug, externalLink }, index) => {
        const isOdd = index % 2 !== 0 ? "even" : "odd";
        res[isOdd].push(
          <InViewport key={`${index}-${imageName}-${isOdd}`} animationDelay="0.3s">
            <ProjectCard
              key={`${index}-${imageName}-${isOdd}`}
              title={title}
              description={subTitle}
              image={`/projects/${imageName}`}
              slug={slug}
              isLeft={isOdd === "odd"}
              externalLink={externalLink}
              isLast={data.length - 1 === index}
            />
          </InViewport>
        );

        return res;
      },
      { even: [], odd: [] }
    );

    if (isProjectsLink) {
      if (isSecondBegin) {
        odd.push(<AllWorksButton key="AllWorksButtonKey" />);
      } else {
        even.push(<AllWorksButton key="AllWorksButtonKey" />);
      }
    }

    if (isBomb) even.unshift(<Bomb key="BombKey" />);

    return (
      <>
        <ProjectsGridColumn second={!isSecondBegin}>{even}</ProjectsGridColumn>
        <ProjectsGridColumn second={isSecondBegin}>{odd}</ProjectsGridColumn>
      </>
    );
  };

  return (
    <ProjectsGridWrapper id={id} isProjectPage={isProjectPage}>
      {isTablet ? renderOneColumn(data) : renderTwoColumn(data)}
    </ProjectsGridWrapper>
  );
};
