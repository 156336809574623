import styled from "styled-components";
import { theme } from "src/styles/global";
import { Link } from "gatsby";

export const SCProjectLink = styled(Link)`
  background: ${theme.colors.white};
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: ${theme.colors.black};
  transform: rotate(20deg) scale(1);
  transition: transform 0.3s cubic-bezier(0.14, 0.48, 0, 0.96);

  @media (${theme.device.tablet}) {
    width: 160px;
    height: 160px;
  }
    
  @media (${theme.device.mobile}) {
    width: 150px;
    height: 150px;
      font-size: 16px;
  }
`;

export const Circle = styled.div`
  width: fit-content;
  position: relative;

  &:after {
    transition: all ${({ time }) => time}s cubic-bezier(0.14, 0.48, 0, 0.96);

    content: "";
    display: block;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, ${({ opacity }) => opacity});
    border-radius: 50%;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    transform-origin: center;
    transform: scale(${({ scale }) => scale});
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 125px;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    transform: translate(0, 0) scale(2.2);
  }

  @media (${theme.device.tablet}) {
    position: static;
    margin-top: 55px;
    transform: translateY(0);
  }

  &:hover,
  &.hover {
    ${Circle} {
      :after {
        transform: scale(1);
      }
    }

    ${SCProjectLink} {
      transform: rotate(-20deg) scale(1.15);
    }
  }
`;
