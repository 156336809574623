import React, { useRef } from "react";
import {
  Card,
  CardFooter,
  Image,
  CustomLink,
  H2,
  Text,
  CustomHref,
  LinkWrapper,
  ImageWrapper,
  Video,
} from "./styled";
import LinkIcon from "src/assets/icons/link-icon.svg";

export const ProjectCard = ({
  title,
  description,
  image = "",
  slug = "/none",
  externalLink,
  isLast = false,
}) => {
  const ref = useRef();

  const onChangeProject = (link) => {
    if (typeof window !== "undefined" && link) {
      window.location.href = `/projects/${link}/`;
    }
  };

  return (
    <Card
      data-card
      data-last={isLast}
      onClick={() => onChangeProject(slug)}
    >
      <ImageWrapper>
        {image?.includes("mp4") ? (
          <Video
            ref={ref}
            src={image}
            muted="muted"
            loop
            onMouseEnter={() => {
              ref.current?.play();
            }}
            onMouseLeave={() => {
              ref.current?.pause();
              ref.current.currentTime = 0;
            }}
          />
        ) : (
          <Image src={image} alt={title} />
        )}
      </ImageWrapper>
      <CardFooter>
        <H2 transform="uppercase">{title}</H2>
        <Card direction="row">
          <Text onClick={() => onChangeProject(slug)}>
            {description}
          </Text>
          <LinkWrapper>
            {externalLink ? (
              <CustomHref onClick={() => onChangeProject(slug)}>
                <LinkIcon />
              </CustomHref>
            ) : (
              <CustomLink onClick={() => onChangeProject(slug)}>
                <LinkIcon />
              </CustomLink>
            )}
          </LinkWrapper>
        </Card>
      </CardFooter>
    </Card>
  );
};

export default ProjectCard;
